/*!

=========================================================
* Argon Dashboard Material-UI - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-material-ui/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "argon-dashboard/custom/variables";

// React Differences
@import "react/react-differences";
.jodit-toolbar-editor-collection_mode_vertical .jodit-toolbar-button__button {
  max-height: 28px !important;
  min-height: 20px !important;
}
.jodit-toolbar-button_variant_initial {
  min-height: 24px !important;
}